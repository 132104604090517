import React from 'react';
import './LearnAndExplore.css';

const exploreLink = "https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d";
const LearnAndExplore = () => {
    return (
        <section className='leParentDiv'>
            <div className= 'leChildDiv'>
                <div className='leContent'>
                    <p className='leTitle'>Our strategy</p>
                    <p className='leSubTitle'>The focus on community engagement combined with enhanced security features positions Filenomics as a unique and innovative financial ecosystem.</p>
                </div>
                <a href={exploreLink} target='_blank' rel="noopener noreferrer">
                    <button className='leBtn'>Explore Now</button>
                </a>
            </div>
        </section>
    )
}

export default LearnAndExplore;