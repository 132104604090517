import React from 'react';
import './Navbar.css';
import languages from '../../icons/languages.svg';
import { Link } from 'react-scroll';
import MobileNav from './MobileNav';


const Navbar = () => {
    return (
      <>
        <header className="navParentDiv">
          <div className='navChildDiv'>
            {/* 3 Main Divs */}

            <div className='navLeftLogo'>
              <p>FILE<span style={{ color: "var(--primary-color)" }}>NOMICS</span></p>
            </div>

            <div className="navItemsDiv">
              <Link to="home" smooth={true} duration={500}>
                <div className="navItem" style={{color: 'var(--primary-color)'}}>Home</div>
              </Link>
              <Link to="community" smooth={true} duration={500}>
                <div className="navItem">Community</div>
              </Link>
              <Link to="market" smooth={true} duration={500}>
                <div className="navItem">Market</div>
              </Link>
              <Link to="learn" smooth={true} duration={500}>
                <div className="navItem">Learn</div>
              </Link>
            </div>

            <div className='navRightSec'>
              <div className='langSection'>
                <img src={languages} alt="langIcon" />
                <select className='langSelect'>
                  <option value="1">EN</option>
                </select>
              </div>
            </div>
          </div>

          {/* Mobile Nav */}
          <div className="mobileNavContainer">
            <MobileNav />
          </div>

        </header>


      </>
    );
}

export default Navbar;

