import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { BiMenu, BiX } from 'react-icons/bi'; // Correct icon names
import './MobileNav.css';

const MobileNav = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={`mobileNav ${isMenuOpen ? 'open' : ''}`}>
      <div className='mobLogo'>
        <p>FILE<span style={{ color: "var(--primary-color)" }}>NOMICS</span></p>
      </div>
      {isMenuOpen ? (
        <BiX className="bi-list" style={{ fontSize: '2rem' }} onClick={closeMenu} />
      ) : (
        <BiMenu className="bi-list" style={{ fontSize: '2rem' }} onClick={toggleMenu} />
      )}

      <div className={`navItemDiv ${isMenuOpen ? 'open' : ''}`}>
        <Link to="home" smooth={true} duration={500} onClick={closeMenu}>
          <div className="navItem" style={{ color: 'var(--primary-color)' }}>Home</div>
        </Link>
        <Link to="community" smooth={true} duration={500} onClick={closeMenu}>
          <div className="navItem">Community</div>
        </Link>
        <Link to="market" smooth={true} duration={500} onClick={closeMenu}>
          <div className="navItem">Market</div>
        </Link>
        <Link to="learn" smooth={true} duration={500} onClick={closeMenu}>
          <div className="navItem">Learn</div>
        </Link>
      </div>
    </nav>
  );
};

export default MobileNav;
