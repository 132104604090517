import React from 'react';
import './Footer.css';
// import insta from '../../icons/instagram-ic.svg'
// import fb from '../../icons/facebook-ic.svg'
import twi from '../../icons/twitter-ic.svg'
import tg from '../../icons/telegram-ic.svg'
// import you from '../../icons/youtube-ic.svg'
// import pdf from './Filenomics_white_paper.pdf'

const currentYear = new Date().getFullYear();
const telegramPageUrl = "https://t.me/+VW1EOZrNBAcxOWEx";
const twitterPageUrl = "https://twitter.com/Filenomics";

const Footer = () => {
    return (
        <section className='footParent'>
            <div className='footChild'>
                {/* 2 Main Divs */}

                {/* 1 */}
                <div className='footLeftDiv'>
                    <div className='footLeftLogo'>
                        <p>FILE<span style={{ color: "var(--primary-color)" }}>NOMICS</span></p>
                    </div>

                    <div className='footLeftSoCo'>
                        <div className='socialDiv'>
                            <a href={telegramPageUrl} target='_blank' rel="noopener noreferrer">
                                <img src={tg} alt='Telegram Icon' /> 
                            </a>
                            <a href={twitterPageUrl} target='_blank' rel="noopener noreferrer">
                                <img src={twi} alt='Twitter Icon' /> 
                            </a>
                        </div>
                        <p className='copyright2'>Total Supply: <span style={{ color: "var(--primary-color)" }}>100,000,000</span></p>
                        <p className='copyright2'>Buy / Sell tax: <span style={{ color: "var(--primary-color)" }}>2%</span></p>
                        <p className='copyright2'>LP locked for: <span style={{ color: "var(--primary-color)" }}>3 years</span></p>

                        <div className='copyright'><b>Get access to our whitepaper <a href="https://drive.google.com/file/d/12hyUTbc3YpoGLDQI3xxg2Z6QQgipRvwY/view?usp=drivesdk" target='_blank' rel="noopener noreferrer">here</a></b></div>
                        <div className='copyright'>{currentYear} <span style={{ color: "var(--primary-color)" }}>Filenomics.</span> All rights reserved</div>
                    </div>
                    <br />
                    <br />
                    <p className='Mobcopyright'><b>Total Supply: <span style={{ color: "var(--primary-color)" }}>100,000,000</span></b></p>
                    <p className='Mobcopyright'><b>Buy / Sell tax: <span style={{ color: "var(--primary-color)" }}>2%</span></b></p>
                    <p className='Mobcopyright'><b>LP locked for: <span style={{ color: "var(--primary-color)" }}>3 Years</span></b></p>
                </div>

                {/* 2 */}
                <div className='footRightDiv'>
                </div>
                <div className='Mobcopyright'>Get access to our whitepaper <b><a href="https://drive.google.com/file/d/12hyUTbc3YpoGLDQI3xxg2Z6QQgipRvwY/view?usp=drivesdk" target='_blank' rel="noopener noreferrer">here</a></b></div>
                <div className='Mobcopyright'>{currentYear} <span style={{ color: "var(--primary-color)" }}>Filenomics.</span> All rights reserved</div>
            </div>
        </section>
    )
}

export default Footer