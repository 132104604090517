import React from 'react';
import './GetStarted.css';
import gs2Img from '../../images/getStarted-2.png';

const exploreLink = "https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d";
const GetStarted = () => {
    return (
        <>
        <div id="learn">
        <section className='gsParentDiv'>
            <div className= 'gsChildDiv'>
                {/* 2 Divs */}
                <div className='gsLeft'>
                    {/* 2 Divs */}
                    <div className='geText'>
                        <p className='gsTitle'>Getting Started</p>
                        <p className='gsSubTitle'>Simple and easy way to start your investment <br/> in <span style={{ color: "var(--primary-color)" }}>FILENOMICS</span></p>
                        <p className='gsSubTitleMob'>Simple and easy way to start your investment in <span style={{ color: "var(--primary-color)" }}>FILENOMICS</span></p>
                    </div>
                    <a className='gsBtn' href={exploreLink} target='_blank' rel="noopener noreferrer">
                        <button>Explore</button>
                    </a>
                </div>

                <div className='gsRight'>
                    {/* Div 1 */}
                    <div className='gsCatBox'>
                        <img className='gsImg' src={gs2Img} alt='img' />
                        <div className='gsCatText'>
                            <p>First Option: </p>
                            <ol>
                                <li>Install Fox wallet</li>
                                <li>Change the existing network to filecoin</li>
                                <li>Copy your wallet address (It begins with fxxxxxxx)</li>
                                <li>Withdraw yoyr funds from cex to your wallet</li>
                                <li><a href="https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d" target='_blank' rel="noopener noreferrer">Swap FIL for Filenomics here</a></li>
                            </ol>
                        </div>
                    </div>

                    {/* Div 2 */}
                    <div className='gsCatBox1'>
                        <img className='gsImg' src={gs2Img} alt='img' />
                        <div className='gsCatText'>
                            <p>Second Option: </p>
                            <ol>
                                <li>Buy FIL on MEXC</li>
                                <li>Connect to <a href="https://www.glif.io/en" target="_blank" rel="noopener noreferrer">GLIF</a></li>
                                <li>On the right top corner - it shows you your FIL wallet</li>
                                <li>Copy it and send funds there</li>
                                <li>On the right corner, go to the send/forward section</li>
                                <li>Paste your metamask address and send FIL there</li>
                                <li>Add <a href="https://chainlist.org/?search=fil" target="_blank" rel="noopener noreferrer">this chain</a> to metamask</li>
                                <li>Swap FIL for Filenomics <a href="https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d" target="_blank" rel="noopener noreferrer">here</a></li>
                            </ol>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        </div>
        </>
    )
}

export default GetStarted