import React from 'react';
import './Features.css';
// import fImg1 from '../../images/features/feature-image 1.png';
import FeatureData from './FeaturesData.json'

const Features = () => {
    return (
      <>
      <div id="community">
      <section className='ftParentDiv'>
            <div className='ftChildDiv'>
                {/* 2 Main Divs */}
                <div className='ftTopDiv'>
                    <p><span style={{ color: "var(--primary-color)" }}>FILENOMICS</span></p>
                    <p>Integrating meme culture with Filecoin network</p>
                </div>

                {/* Mapped 4 Boxes */}
                <div className='ftBottomDiv'>
                    {
                        FeatureData.map(data =>{
                            return (
                              <div className="featureBox" key={data.id}>
                                <div className="featureInfo" >
                                  <div className="featureContent">
                                    <img src={data.img} alt="img" />
                                    <div className="featureContDiv">
                                      <p>{data.heading}</p>
                                      <p>{data.description}</p>
                                    </div>
                                  </div>
                                  <div className="featureLink">
                                    <a href="https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d" target='_blank' rel="noopener noreferrer">Explore now &rarr;</a>
                                  </div>
                                </div>
                              </div>
                            )
                        })
                    }   
                </div>
            </div>
        </section>
      </div>
      </>
        // <section className='ftParentDiv'>
        //     <div className='ftChildDiv'>
        //         {/* 2 Main Divs */}
        //         <div className='ftTopDiv'>
        //             <p><span style={{ color: "var(--primary-color)" }}>FILENOMICS</span></p>
        //             <p>Integrating meme culture with Filecoin network</p>
        //         </div>

        //         {/* Mapped 4 Boxes */}
        //         <div className='ftBottomDiv'>
        //             {
        //                 FeatureData.map(data =>{
        //                     return (
        //                       <div className="featureBox" key={data.id}>
        //                         <div className="featureInfo" >
        //                           <div className="featureContent">
        //                             <img src={data.img} alt="img" />
        //                             <div className="featureContDiv">
        //                               <p>{data.heading}</p>
        //                               <p>{data.description}</p>
        //                             </div>
        //                           </div>
        //                           <div className="featureLink">
        //                             <a href="https://www.sushi.com/swap?chainId=314&token0=NATIVE&token1=0xA309f3638DF67291F882069B37774D411923254d" target='_blank' rel="noopener noreferrer">Explore now &rarr;</a>
        //                           </div>
        //                         </div>
        //                       </div>
        //                     )
        //                 })
        //             }   
        //         </div>
        //     </div>
        // </section>
    )
}

export default Features