import React from 'react';
import landing from '../../images/landing.jpg';
import './StartAndBuild.css';

const StartAndBuild = () => {
    return (
        <>
        <div id="home">
        <section className='sbParentDiv'>
            <div className='sbChildDiv'>
                <div className='sbLeftDiv'>
                    <div className='sbLeftChildDiv'>
                        <div>What is <span style={{ color: "var(--primary-color)" }}>FILENOMICS?</span></div>
                        <div>Filenomics is a revolutionary memecoin merging humour and <br/>
                        decentralization on the Filecoin network.</div>
                    </div>
                    <a href="https://t.me/+VW1EOZrNBAcxOWEx" target='_blank' rel="noopener noreferrer">
                        <button className='sbBtn'>Get Started</button>
                    </a>
                </div>
                <div className='sbRightDiv'>
                    <div className='sbImgDiv'>
                        <img src={landing} alt='img'/>
                    </div> 
                </div>
            </div>
        </section>
        </div>
        </>
    )
}

export default StartAndBuild